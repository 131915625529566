.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.section--zero h2 {
  font-size: 85px;
  line-height: 1;
}
.section--one .area {
  width: 100%;
}
.section--one .area > .unit {
  margin-right: 1.26582278%;
  margin-left: 1.26582278%;
  width: 97.46835443%;
}
.section--one .area .cb-album .body,
.section--one .area .head,
.section--one .area .foot,
.section--one .area .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--one .area > .slim {
  width: 47.46835443%;
}
.section--one .area > .slim .head,
.section--one .area > .slim .foot,
.section--one .area > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--one .area > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--one .area > .slim .tiny {
  width: 100%;
}
.section--four .area {
  width: 100%;
}
.section--four .area > .unit {
  margin-right: 1.57232704%;
  margin-left: 1.57232704%;
  width: 96.85534591%;
}
.section--four .area .cb-album .body,
.section--four .area .head,
.section--four .area .foot,
.section--four .area .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--four .area > .slim {
  width: 30.18867925%;
}
.section--four .area > .slim .head,
.section--four .area > .slim .foot,
.section--four .area > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--four .area > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--four .area > .slim .tiny {
  width: 100%;
}
.section--six .area {
  width: 100%;
}
.section--six .area > .unit {
  margin-right: 1.57232704%;
  margin-left: 1.57232704%;
  width: 96.85534591%;
}
.section--six .area .cb-album .body,
.section--six .area .head,
.section--six .area .foot,
.section--six .area .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--six .area > .slim {
  width: 30.18867925%;
}
.section--six .area > .slim .head,
.section--six .area > .slim .foot,
.section--six .area > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--six .area > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--six .area > .slim .tiny {
  width: 100%;
}
.section--one .area {
  width: 102.5974026%;
  margin-left: -1.62337662%;
  display: flex;
  flex-wrap: wrap;
}
.section--four .area {
  width: 103.24675325%;
  margin-left: -1.62337662%;
  display: flex;
  flex-wrap: wrap;
}
.section--six .area {
  width: 103.24675325%;
  margin-left: -1.62337662%;
  display: flex;
  flex-wrap: wrap;
}
.section--one .area .slim.seam {
  padding-top: 40px;
  box-sizing: border-box;
}
.section--four .area .unit .part {
  margin-top: 1.57232704vw;
  margin-bottom: 1.57232704vw;
}
.section--four .area .seam.wide {
  width: 63.52201258%;
}
/*# sourceMappingURL=./screen-medium.css.map */